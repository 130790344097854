import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, Grid, Stack, SvgIconProps, Typography } from '@mui/material';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import SelectableDemoForms, { SelectableDemoFormsProps } from '../SelectableDemoForms';

interface DemoStepProps {
  icon: (props: SvgIconProps) => React.ReactNode;
  title: string;
}

const DemoStep = (props: React.PropsWithChildren<DemoStepProps>) => (
  <Stack spacing={2}>
    <Stack spacing={2} direction='row' alignItems='center' fontSize='40px'>
      {props.icon({ fontSize: 'inherit' })}
      <Typography variant='h2'>{props.title}</Typography>
    </Stack>
    <Typography>{props.children}</Typography>
  </Stack>
);

// TODO: Zamiast default dać 2 eksporty: DemoFormSection (z domyślnym tytułem) i DemoFormSectionContent.
// TODO: Analogicznie inne sekcje dla łatwiejszego "kopiowania" między stronami.
export default (props: SelectableDemoFormsProps) => (
  <Grid container spacing={6} mt={-3} mb={3}>
    <Grid item xs={12} md={7}>
      <Stack spacing={4}>
        <DemoStep icon={(_) => <LooksOneIcon {..._} />} title='Wypełnij formularz kontaktowy'>
          Uzupełnij wszystkie dane, abyśmy mogli wysłać wersję demo dostosowaną do Twojej placówki.
        </DemoStep>
        <DemoStep icon={(_) => <LooksTwoIcon {..._} />} title='Otwórz e-mail z linkiem do demo'>
          Teraz możesz zobaczyć, jak działa Mediporta. W każdej chwili możesz skorzystać z szeregu wskazówek i
          przewodników zawartych w programie.
        </DemoStep>
        <DemoStep icon={(_) => <Looks3Icon {..._} />} title='Porozmawiaj z naszym ekspertem'>
          Doradzimy Ci, jakie rozwiązanie będzie najlepsze dla Twojej placówki.
        </DemoStep>
        <Box display={{ xs: 'none', md: 'initial' }}>
          <StaticImage src='../../images/computer.png' alt='' />
        </Box>
      </Stack>
    </Grid>
    <Grid item xs={12} md={5}>
      <SelectableDemoForms {...props} />
    </Grid>
  </Grid>
);
